export const APPLICATION_TITLE: string =
	"Toma Pedido Back-Office | 360 Sales Solutions";

export const USER: string = "User";

export const TOKEN: string = "Token";
export const FINGERPRINT: string = "Fingerprint";
export const SIGNATURE: string = "Signature";
export const ENTITY: string = "Entity";
export const APPLICATION: string = "Application";

export const OBJECT: string = "Object";
export const ALLOWED_APP: string = "App";
export const ALLOWED_MENUS: string = "Menus";
export const ALLOWED_FORMS: string = "Forms";
export const ALLOWED_OPTIONS: string = "Options";
export const SELECTED_APP: string = "SelectedApp";
export const SELECTED_MENU: string = "SelectedMenu";
export const SELECTED_FORM: string = "SelectedForm";
export const EXPIRED_DIALOG_OPENED: string = "Opened";
export const SELECTED_PAYMENT_RECEIPT = "p@ym3nt-r3c31pt-p@r@m";
export const SELECTED_OPENING_MODEL = "0p3n1ng-p@r@m";
export const PARAMETERS = "Parameters";
export const CASH_REGISTER = "CashRegister";
export const USER_INFO = "UserInfo";

export const ORDER_MODEL_URL = "order_model_url";
export const ORDER_MODEL_PROCESS_TYPE = "order_model_type";

export const NON_DEVELOPED_FUNCTIONALITY = "Funcionalidad no implementada";

export const REG_INT = /^\d+$/;
export const REG_DOUBLE = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
export const REG_DOUBLE_THREE_DECIMALS = /^\s*(?=.*[0-9])\d*(?:\.\d{1,3})?\s*$/;
export const REG_DOUBLE_TWO_DECIMALS = /^\d+(\.\d{1,2})?$/;
export const PATTERN_PASSWORD =
	"^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,20}$";

export const ExpiredSession = {
	yes: 1,
	no: 0
};

export const TEMP_STORE = "STORE001";

export const TABLE_PAGE_SIZES: number[] = [10, 20, 50];
// export const TABLE_PAGE_SIZES: number[] = [50, 50, 50];
// export const TABLE_PAGE_SIZES: number[] = [5, 7, 10];

export const SALE_ORDER_STATUS = Object.freeze({
	opened: "Abierto",
	closed: "Cerrado",
	saved: "Guardado",
	picking: "Picking",
	canceled: "Cancelado",
	charge: "Cargo",
	removed: "Eliminado",
	locked: "Bloqueado",
	editing: "Edición",
	pendingApproval: "Por aprobar",
	approved: "Aprobado",
	rejected: "Rechazado",
	nullified: "Anulado"
});

export const MOTIVE_STATUS = Object.freeze({
	active: "Activo",
	inactive: "Inactivo"
});

export const ZONE_STATUS = Object.freeze({ active: "Activo", inactive: "Inactivo" });

export const APPLICATION_CODES = Object.freeze({
	takeOrderMobile: "app-takeOrderSystem-mobile",
	takeOrderBackOffice: "app-takeOrderBackOfficeSystem"
});

export interface MonthModel {
	name: string;
	shortName: string;
	number: number;
}

export const MONTHS: MonthModel[] = [
	{ name: "Enero", shortName: "Ene.", number: 1 },
	{ name: "Febrero", shortName: "Feb.", number: 2 },
	{ name: "Marzo", shortName: "Mar.", number: 3 },
	{ name: "Abril", shortName: "Abr.", number: 4 },
	{ name: "Mayo", shortName: "May.", number: 5 },
	{ name: "Junio", shortName: "Jun.", number: 6 },
	{ name: "Julio", shortName: "Jul.", number: 7 },
	{ name: "Agosto", shortName: "Ago.", number: 8 },
	{ name: "Setiembre", shortName: "Set.", number: 9 },
	{ name: "Octubre", shortName: "Oct.", number: 10 },
	{ name: "Noviembre", shortName: "Nov.", number: 11 },
	{ name: "Diciembre", shortName: "Dic.", number: 12 }
];

export enum QuotaModelPropEnum {
	SALES = "sales",
	VISITS = "visits",
	EXCEEDED_VISITS = "exceededVisits",
	EXCEEDED_SALES = "exceededSales",
	EXCEEDED_VISITS_PARENT = "exceededVisitsParent",
	EXCEEDED_SALES_PARENT = "exceededSalesParent"
}

export enum SemaphoreTypesEnum {
	SALES = "sales",
	VISITS = "visits"
}

export enum SemaphorePeriodicityEnum {
	MONTHLY = "monthly",
	ANNUALLY = "annually"
}

export enum SemaphoreLevelEnum {
	LOW = "low",
	MID = "mid",
	HIGH = "high"
}
