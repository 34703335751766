import { hostSelected } from "./enviroment.conts";

export const environment = {
	production: false,
	security_engine: hostSelected.host+"secengine/",
	security_operation : hostSelected.host+"secoperation/",
	admin_operation : hostSelected.host+"admoperation/",
	process_operation : hostSelected.host+"process-operation/",
	sync_engine : hostSelected.host+"sync-engine/",

	pos_engine : hostSelected.host+"posengine/",
	pos_operation : hostSelected.host+"posoperation/",
	report_engine : hostSelected.host+"report-engine/",
	take_order_engine: hostSelected.host+"takeorder-engine/",
	take_order_operation : hostSelected.host+"takeorder-operation/"
};
