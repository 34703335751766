export const HOST = {
	GENERAL:		'https://services.360salesolutions.com/',
	NDP: 			'https://spdev.360salesolutions.com',
	NDP_TEST:		'http://spdev-test.360salesolutions.com/',
	NDP_DEMO: 		'https://demo.service.360salesolutions.com/',
	GPC_TEST: 		'https://test.servicepos.gpcperu.com/',
	GPC_TEST2: 		'https://test.services.gpcperu.com/',
	MAXX: 			'https://maxx.services.360salesolutions.com/',
	GAVILON_TEST: 	'https://test.serviceto.gavilon.com/',
	GAVILON_PROD: 	'https://serviceto.macrosource.com/',
	DEMO_TAKE_ORDER:'https://demo.takeorder.service.360salesolutions.com'

}
	
//COMPONENTES SEGUN EMPRESA
export const HOST_VIEW = {

    GPC : 'GPC',
    MAXX: 'MAXX',
	GAVILON:'GAVILON'
}

export const hostSelected = {
    host: HOST.GAVILON_PROD,
    view: HOST_VIEW.GAVILON
}
