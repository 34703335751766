import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs";
import * as Constants from "src/app/shared/constants/constants";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const token = sessionStorage.getItem(Constants.TOKEN);

		if (token) return true;
		else {
			this.router.navigate(["auth"]);
			return false;
		}
	}
}
